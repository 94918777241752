export const options = [
  {
    label: 'id',
    prop: 'Id',
    width: 80
  },
  {
    label: '上级id',
    prop: 'ParentId',
    width: 80
  },
  {
    label: '用户地址',
    prop: 'UserAddress',
    width: 380
  },
  {
    label: '会员有效期',
    prop: 'SubscriptionExpiration',
    width: 380
  },
  {
    label: '注册日期',
    prop: 'CreatedAt'
  },
  {
    label: '操作',
    prop: 'action',
    width: 160
  }
]
